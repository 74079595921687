import { useState } from "react";
import Chip from "./Chip";

function NewsCard({ data, loadNewsWithTag }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={`drop-shadow-md rounded-lg bg-white p-7 overflow-hidden relative ${
        showMore ? "" : "h-96"
      }`}
    >
      <a
        href={data.canonical[0].href}
        className="text-xl font-bold block"
        dangerouslySetInnerHTML={{ __html: data.title }}
        target="_blank"
        rel="noreferrer"
      ></a>

      {data.tags.map((tag) => (
        <Chip key={tag} label={tag} loadNewsWithTag={loadNewsWithTag} />
      ))}

      <p
        className="my-3"
        dangerouslySetInnerHTML={{
          __html: data.summary.content,
        }}
      ></p>

      <div
        className="p-2 text-sm absolute bottom-0 left-0 right-0 text-center bg-gray-200 hover:bg-gray-100 cursor-pointer"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Show Less" : "Show More"}
      </div>
    </div>
  );
}

export default NewsCard;
