function Chip({ label, loadNewsWithTag }) {
  return (
    <span
      href={`#${label}`}
      className="rounded-lg bg-gray-500 hover:bg-gray-300 text-xs py-1 px-2 mt-3 mr-3 mb-5 text-white cursor-pointer"
      onClick={() => loadNewsWithTag(label)}
    >
      {label}
    </span>
  );
}

export default Chip;
