import axios from "axios";
import { useEffect, useState } from "react";
import NewsCard from "./NewsCard";

function App() {
  const [news, setNews] = useState([]);
  const [tag, setTag] = useState(null);
  const [after, setAfter] = useState(null);

  useEffect(() => {
    loadNews();
    // eslint-disable-next-line
  }, [tag]);

  const loadNewsWithTag = (tag) => {
    setTag(tag);
    setAfter(null);
  };

  const loadNews = () => {
    var params = "size=21";
    if (tag) params += `&tag=${tag}`;
    if (after) params += `&after=${after}`;

    axios
      .get("https://fscom-get-news.forsint.workers.dev/?" + params)
      .then((response) => {
        setAfter(response.data.data.after[0]["@ref"].id);
        if (after) setNews([...news, ...response.data.data.data]);
        else setNews(response.data.data.data);
      });
  };

  return (
    <div class="container mx-auto p-3">
      <h1 className="text-center text-6xl text-bold mb-4 text-white bg-blue-900 p-4">
        Forsint News
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {news.map((n) => (
          <NewsCard key={n.ts} {...n} loadNewsWithTag={loadNewsWithTag} />
        ))}
      </div>

      <div
        className="p-2 bg-gray-300 hover:bg-gray-200 rounded my-3 text-center text-sm cursor-pointer"
        onClick={loadNews}
      >
        Load more
      </div>
    </div>
  );
}

export default App;
